import { Heading, Text } from 'aslan';

import {
  useLoadedTranslations,
  TranslationNamespace,
} from '~/helpers/getTranslations';

export function ClientError({ status }: { status: number }) {
  const customResponses = [400, 401, 403, 404];
  const value = customResponses.includes(status) ? status : 'default';
  const { t } = useLoadedTranslations(TranslationNamespace.root);
  return (
    <>
      <Heading as="h1" size="3xl" className="font-serif">
        {t(`${value}-line1`)}
      </Heading>
      <Text size="lg">{t(`${value}-line2`)}</Text>
      {value === 404 ? <Text size="lg">{t(`${value}-line3`)}</Text> : null}
    </>
  );
}
