import { Heading, Text } from 'aslan';

import {
  useLoadedTranslations,
  TranslationNamespace,
} from '~/helpers/getTranslations';

export function ServerError() {
  const { t } = useLoadedTranslations(TranslationNamespace.root);
  return (
    <>
      <Heading as="h1" size="3xl" className="font-serif">
        {t('server-line1')}
      </Heading>
      <Text size="lg">{t('server-line2')}</Text>
      <Text size="lg">
        {t('server-line3')}{' '}
        <a className="underline" href="mailto:beta@theleap.co">
          {t('contact-us')}
        </a>
      </Text>
    </>
  );
}
