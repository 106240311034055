import { Link } from '@remix-run/react';
import { twMerge } from 'tailwind-merge';

import { ButtonInner } from 'aslan';

import {
  useLoadedTranslations,
  TranslationNamespace,
} from '~/helpers/getTranslations';
import { loginPath } from '~/helpers/routes';
import { ExtendedTwirl } from '../NavBar/ExtendedTwirlSVG';

import type { PropsWithChildren } from 'react';

interface ErrorLayoutProps {
  children: PropsWithChildren<React.ReactNode>;
  className?: string;
}

export const ErrorLayout = ({ children, className }: ErrorLayoutProps) => {
  const { t } = useLoadedTranslations(TranslationNamespace.root);

  return (
    <div
      className={twMerge(
        'w-full min-h-full error-page-background-image text-center',
        className
      )}
      data-testid="error-layout"
    >
      <main className="flex flex-col space-y-10 items-center justify-center mx-auto w-full max-w-xl p-4">
        {/* Header */}
        <ExtendedTwirl />
        {/* Error Response Custom Messaging */}
        {children}
        {/* Footer */}
        <div className="flex items-center">
          <Link to={loginPath()} className="w-full">
            <ButtonInner
              color="yellow"
              isRounded
              isFullWidth
              className="my-3 md:pr-2"
            >
              {t('go-to-login-page')}
            </ButtonInner>
          </Link>
        </div>
      </main>
    </div>
  );
};
