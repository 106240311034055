interface CloudflareInsightsProps {
  cfBeacon: string;
}

export default function CloudflareInsights({
  cfBeacon,
}: CloudflareInsightsProps) {
  return (
    <>
      {cfBeacon && (
        <script
          defer
          src="https://static.cloudflareinsights.com/beacon.min.js"
          data-cf-beacon={`{"token": "${cfBeacon}"}`}
        ></script>
      )}
    </>
  );
}
