import { json, redirect } from '@remix-run/cloudflare';
import {
  isRouteErrorResponse,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError } from
'@remix-run/react';
import * as Sentry from '@sentry/remix';
import { Toaster } from 'react-hot-toast';
import rdtStylesheet from 'remix-development-tools/index.css';

import { useToaster } from 'aslan';
import { GoogleTagManagerDataLayer, GoogleTagManagerNoScript } from 'analytics';
import {
  getRedirectTo,
  getSession,
  isLoggedIn,
  getToastMessage } from
'leap-auth';
import { logBoundaryErrors } from 'utils';

import ErrorLayout from '~/components/ErrorLayout';
import { MAIN_CONTENT_ID } from '~/constants';
import {
  getTranslationsObject,
  TranslationNamespace,
  useLoadedTranslations } from
'~/helpers/getTranslations';
import { creatorAdminUrl, logoutPath } from '~/helpers/routes';
import styles from '~/styles/tailwind.css';
import CloudflareInsights from './components/CloudflareInsights';
import { ClientError } from './components/ErrorLayout/ClientError';
import { ServerError } from './components/ErrorLayout/ServerError';
import ExposeAppConfig from './components/ExposeAppConfig';

import type {
  LinksFunction,
  LoaderFunction,
  LoaderFunctionArgs } from
'@remix-run/cloudflare';

export const loader: LoaderFunction = async (args: LoaderFunctionArgs) => {
  const { session, commitSession } = await getSession(args);
  const toastMessage = await getToastMessage(session);
  const data = {
    appConfig: {
      // * WARNING *: Do not expose any sensitive variables here since it is included in the bundles
      mixpanelToken: args.context.env.MIXPANEL_TOKEN,
      sentryDsn: args.context.env.SENTRY_DSN,
      cfBeacon: args.context.env.CF_BEACON,
      remixApp: args.context.env.REMIX_ENV,
      googleAnalyticsId: args.context.env.GTM_ID
      // * END WARNING *
    },
    error: session.get('error'),
    toastMessage,
    ...getTranslationsObject()
  };

  const url = new URL(args.request.url);
  if (isLoggedIn(session) && url.pathname !== logoutPath()) {
    // Redirect back where they came from or to the creator admin
    return redirect(getRedirectTo(args, session, creatorAdminUrl), {
      headers: {
        'Set-Cookie': await commitSession(session)
      }
    });
  }

  return json(data, {
    headers: {
      'Set-Cookie': await commitSession(session)
    }
  });
};

export const links: LinksFunction = () => {
  return [
  {
    rel: 'preload',
    as: 'font',
    href: '/fonts/favorit-bold.woff2',
    type: 'font/woff2',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'preload',
    as: 'font',
    href: '/fonts/favorit-regular.woff2',
    type: 'font/woff2',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'preload',
    as: 'font',
    href: '/fonts/tobias-light.woff2',
    type: 'font/woff2',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'apple-touch-icon',
    href: '/apple-touch-icon.png',
    type: 'image/png',
    sizes: '180x180'
  },
  {
    rel: 'mask-icon',
    href: '/safari-pinned-tab.svg',
    color: '#f0edee'
  },
  {
    rel: 'icon',
    href: '/favicon-16x16.png',
    sizes: '16x16',
    type: 'image/png'
  },
  {
    rel: 'icon',
    href: '/favicon-32x32.png',
    sizes: '32x32',
    type: 'image/png'
  },
  { rel: 'stylesheet', href: styles },
  ...(process.env.NODE_ENV === 'development' ?
  [{ rel: 'stylesheet', href: rdtStylesheet }] :
  [])];

};

let AppExport = App;

if (process.env.NODE_ENV === 'development') {
  const { withDevTools } = require('remix-development-tools');
  AppExport = withDevTools(AppExport);
}

interface LoaderDataType {
  appConfig: {
    mixpanelToken: string;
    sentryDsn: string;
    cfBeacon: string;
    remixApp: string;
    googleAnalyticsId: string;
  };
  toastMessage?: any;
}

function App() {
  const { appConfig, toastMessage } = useLoaderData<LoaderDataType>();

  // Hooks to render toaster
  useToaster(toastMessage);

  return (
    <html lang="en" className="leading-normal">
      <head>
        {/*
           Google Tag Manager Requirement #1
           This helps to ensure that your Tag Manager
           configuration is available and ready when the
           rest of the page loads.
          */}
        {appConfig.googleAnalyticsId &&
        <GoogleTagManagerDataLayer
          googleAnalyticsId={appConfig.googleAnalyticsId} />}


        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <ExposeAppConfig appConfig={appConfig} />
      </head>
      <body className="antialiased h-full bg-gray-100 text-brown-900">
        {appConfig.googleAnalyticsId &&
        <GoogleTagManagerNoScript id={appConfig.googleAnalyticsId} />}

        <a
          className="sr-only focus:not-sr-only focus:p-2 focus:m-2 focus:inline-block focus:top focus:absolute focus:bg-black focus:text-white focus:text-lg focus:font-bold focus:z-50"
          href={`#${MAIN_CONTENT_ID}`}>

          Skip to content
        </a>
        <Toaster position="top-right" />
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <CloudflareInsights cfBeacon={appConfig.cfBeacon} />
        <LiveReload />
      </body>
    </html>);

}

export function ErrorBoundary() {
  const error = useRouteError();
  const { t } = useLoadedTranslations(TranslationNamespace.root);
  logBoundaryErrors(error, Sentry);

  return (
    <html lang="en" className="leading-normal">
      <head>
        <title>{`${t('response-error-boundary-title')} | The Leap`}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="antialiased h-full bg-gray-100 text-brown-900">
        <ErrorLayout>
          {isRouteErrorResponse(error) ?
          <ClientError status={error.status} /> :

          <ServerError />}

        </ErrorLayout>
        <Scripts />
      </body>
    </html>);

}

export default Sentry.withSentry(AppExport);